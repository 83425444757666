.friends-home-header-wrapper, .app-title {
  margin: 35px auto;
  text-align: center;

  h1 {
    color: #eeeeee;
    letter-spacing: 2px;
    font-family: Impact, Arial, sans-serif;
    text-transform: uppercase;

    i {
      font-size: 1.25em;
    }
  }
}
