.navbar-inverse {
  font-size: 14px;
  background-color: #171a21;
  border-color: #080808;
  background-image: none;
  color: #b8b6b4;
  text-transform: uppercase;

  .navbar-brand {
    color: #eee;
    text-shadow: #2a2a2a 1px 1px 0px !important;
    font-family: Impact, Arial, sans-serif;
    letter-spacing: 1px;
    font-size: 28px;
  }

  .navbar-collapse {
    .navbar-nav {
      .dropdown {
        display: none;
      }

      .steam-account {
        a {
          color: #8bb9e0;

          &:hover {
            color: #FFFFFF;
          }
        }
      }

      .steam-logout,
      .steam-login {
        .btn-primary {
          text-transform: capitalize;
          background-color: transparent;
          background-image: none;
          border-color: #9d9d9d;
          color: #9d9d9d;

          &:hover {
            border-color: #ffffff;
            color: #ffffff;
          }

          &:active {
            background-color: transparent;
          }
        }
        @media screen and (max-width: 767px) {
          .btn-group {
            padding-left: 15px;
          }
        }
      }
    }
  }
}
