.footer {
  position: absolute;
  bottom: 0;
  width: 100%;

  &.footer-inverse {
    font-size: 14px;
    background-color: #171a21;
    border-color: #080808;
    background-image: none;

    .container {
      .footer-nav {
        text-align: center;
        width: 100%;
      }

      .site-info {
        max-width: 640px;
        padding: 5px 0;
        margin: 0 auto;
        color: #b0aeac;
        font-size: 0.7em;
        text-align: center;
        clear: both;

        .fa {
          margin-right: 0.5em;
        }
      }
    }
  }
}
