.account-search-form {
  width: 440px;
  margin: auto;

  label {
    font-size: 11px;
  }

  .input-group {
    .steam-profile-url {
      padding: 6px;
      font-size: 12px;
      color: grey;
      border-width: 0;
      border-right: 1px solid #CCCCCC;
    }

    #basic-url {
      border-color: #FFFFFF;
    }

    .search-submit {
      text-shadow: none;
      cursor: pointer;
      color: #e5e4dc;
      background: #61892b;
      border: 1px solid #61892b;
      text-shadow: none;
      background-image: none;
      box-shadow: none;

      &:hover {
        background: #84bc3c;
        border: 1px solid #84bc3c;
      }
    }
  }

  @media only screen and (max-width: 519px) {
    width: 210px;

    label {
      display: none;
    }

    .input-group {
      .steam-profile-url {
        display: none;
      }

      .basic-url {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }
}
